import { useState } from 'react';

import { cn } from '@/utils/helper';

const tabs = [
  {
    tab: 'Borrowers',
    steps: [
      {
        title: 'List Your Digital Asset (NFT or RWA)',
        info: 'List your NFT or RWA and specify the terms you want for the loan. Once listed, other users will start sending you loan offers.',
      },
      {
        title: 'Receive and Accept Offers',
        info: "Review the loan offers you receive and choose the best one. Once you accept an offer, your NFT or RWA is securely placed in an escrow smart contract, and you'll receive wETH, or USDC directly in your wallet from the lender.",
      },
      {
        title: 'Repay and Retrieve Your Asset',
        info: 'Repay the loan on time to automatically get your NFT or RWA back in your wallet.',
      },
    ],
  },
  {
    tab: 'Lenders',
    steps: [
      {
        title: 'Browse and Offer',
        info: 'Explore the listed NFTs, RWAs and collections seeking loan offers. You can make custom offers or agree to the borrower’s specified loan terms.',
      },
      {
        title: 'Loan Offer Accepted',
        info: 'Once the borrower accepts your loan offer, your principal is transferred to them, and the NFT or RWA is securely held in an escrow smart contract for the loan period.',
      },
      {
        title: 'Earn Interest',
        info: "When the borrower repays the loan, you'll automatically receive your principal along with the interest directly in your wallet.",
      },
    ],
  },
  {
    tab: 'API/SDK',
    steps: [
      {
        title: 'Start Building with NettyWorth',
        info: 'NettyWorth provides robust financialization infrastructure and deep liquidity for your digital asset (NFT) needs. Enhance your existing offerings with financial capabilities and monetize your audience effectively.',
      },
      {
        title: 'Access Our API/SDK',
        info: 'Integrate your dApp seamlessly with our protocol using our API/SDK tools.',
      },
      {
        title: 'Connect with Us ',
        info: "We'd love to discuss your project and explore how we can elevate digital asset finance together. Reach out to start the conversation.",
      },
    ],
  },
];
const HowWork = () => {
  const [active, setActive] = useState(0);
  const handleChange = (idx: number) => setActive(idx);
  return (
    <div id='how-work' className='!bg-midnight-blue lg:!bg-jet-black relative pb-[364px]'>
      <h2 className='font-bold text-[32px]/[25px] lg:text-[54px]/[45px] text-white text-center relative'>
        How it <span className='text-primary font-bold text-[32px]/[25px] lg:text-[54px]/[45px]'>Works</span>
      </h2>
      <div className='overflow-x-auto scrollbar-hide !bg-transparent md:flex justify-center'>
        <div className='flex justify-center gap-[19px] pt-[26px] lg:pt-[47px] !bg-transparent px-5 w-max lg:px-0'>
          {tabs.map(({ tab }, idx) => {
            return (
              <div key={idx} className='!bg-transparent'>
                <button onClick={() => handleChange(idx)} className={cn('whitespace-nowrap rounded-3xl px-[58px] py-[18px] hover:!bg-[#E500E5] font-semibold text-xl text-white', active === idx ? '!bg-[#E500E5]' : '!bg-primary')}>
                  {tab}
                </button>
              </div>
            );
          })}
        </div>
      </div>
      <div className='grid lg:grid-cols-3 max-w-[1218px] gap-4 md:gap-7 xl:gap-[42px] m-auto w-full mt-[26px] lg:mt-[68px] px-5 xl:px-0 !bg-transparent'>
        {tabs[active].steps.map(({ title, info }, idx) => {
          return (
            <div key={idx} className='rounded-3xl !bg-primary p-[38px] h-[487px] lg:h-[500px] xl:h-[487px] sm:max-w-[378px] m-auto relative z-10'>
              <p className='text-[127px]/[32px] text-white shadow-text pt-[60px] text-center'>{idx + 1}</p>
              <p className='text-[27px]/[32px] font-semibold text-white pt-[60px]'>{title}</p>
              <p className='font-normal text-base/[25px] text-white mt-2'>{info}</p>
            </div>
          );
        })}
      </div>
      <img src='/images/how-work-graph.svg' alt='graph' className='absolute bottom-[264px] max-w-[400px] lg:max-w-full lg:bottom-[-130px] right-0' />
    </div>
  );
};

export default HowWork;
