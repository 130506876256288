import Feedback from '@/components/layouts-and-navs/landing/home/Feedback';
import HeroSection from '@/components/layouts-and-navs/landing/home/HeroSection';
import HowWork from '@/components/layouts-and-navs/landing/home/HowWork';
import NFTLoans from '@/components/layouts-and-navs/landing/home/NFTLoans';
import PortfolioSection from '@/components/layouts-and-navs/landing/home/PortfolioSection';

const MainPage = () => {
  return (
    <>
      <HeroSection />
      <PortfolioSection />
      <NFTLoans />
      <HowWork />
      <Feedback />
    </>
  );
};

export default MainPage;
