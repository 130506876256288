import React, { useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import { cn } from '@/utils/helper';

const portfolioItems = [
  {
    emoji: '/images/emoji-dollar.svg',
    title: 'Total Value',
    txt: 'Instantly see how much your crypto and NFTs are worth.',
    phone: '/images/phone-value.png',
    topImage: '/images/top_image_total.png',
    graph: '/images/pink_graph.svg',
  },
  {
    emoji: '/images/emoji-happy.svg',
    title: 'Profit & Loss',
    txt: " Keep an eye on how much money you're making or losing.",
    phone: '/images/phone-lose.png',
    topImage: '/images/top_image_loss.png',
    graph: '/images/pink_graph.svg',
  },
  {
    emoji: '/images/emoji-heart.svg',
    title: 'Watch List',
    txt: 'Follow your favorite crypto and NFT projects',
    phone: '/images/phone-watchlist.png',
    topImage: '/images/top_image_watch.png',
    graph: '/images/pink_graph.svg',
  },
  {
    emoji: '/images/emoji-eye.svg',
    title: 'Loan Suggestions',
    txt: 'Get insights on which assets to borrow and lend for maximum crypto earnings.',
    phone: '/images/phone-loans.png',
    topImage: '/images/top_image_loan.png',
    graph: '/images/pink_graph.svg',
  },
];
interface IPortfolioItem {
  item: { emoji: string; title: string; txt: string };
  activeSlide?: number;
  index?: number;
  stickySlide?: boolean;
  handleChangeSlide?: (index: number) => void;
}
const PortfolioItem: React.FC<IPortfolioItem> = ({ handleChangeSlide, item, activeSlide, index, stickySlide }) => {
  const { emoji, title, txt } = item;
  return (
    <div
      onClick={() => {
        if (stickySlide && handleChangeSlide) {
          handleChangeSlide(index as number);
        }
      }}
      className={cn('cursor-pointer flex rounded-lg px-[26px] lg:px-7 xl:px-[37px] py-[30px] lg:py-5 xl:py-[30px] w-full max-w-[579px] gap-3 min-[440px]:gap-6 active-slide flex-col lg:flex-row h-[320px] min-[440px]:h-[282px] sm:h-[250px] lg:h-auto', stickySlide && activeSlide === index ? '!bg-primary' : '!bg-light-purple')}
    >
      <img src={emoji} alt='emoji' className='w-max min-w-[65px] self-center lg:self-start' />
      <div className='flex flex-col gap-[6px] !bg-transparent'>
        <p className={cn('font-semibold text-[28px]/[32px] max-[472px]:h-[64px]', stickySlide && activeSlide === index ? '!text-white' : '!text-dark-purple')}>{title}</p>
        <p className={cn('text-base lg:text-[19px]/[27px] font-normal', stickySlide && activeSlide === index ? '!text-white' : '!text-dark-purple-100')}>{txt}</p>
      </div>
    </div>
  );
};
const PortfolioSection = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const handleChangeSlide = (idx: number) => setActiveSlide(idx);
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollPosition = window.scrollY;
  //     const windowHeight = window.innerHeight;
  //     const contentElement: HTMLElement | null = document.querySelector('.content');

  //     if (contentElement) {
  //       const sectionHeight = contentElement.offsetHeight;

  //       const scrollThresholds = [0, sectionHeight + 200, sectionHeight + 400, sectionHeight + 600, document.body.clientHeight - windowHeight];

  //       for (let i = 0; i < scrollThresholds.length - 1; i++) {
  //         if (scrollPosition >= scrollThresholds[i] && scrollPosition < scrollThresholds[i + 1]) {
  //           setActiveSlide(i);
  //           break;
  //         }
  //       }
  //     }
  //   };

  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, []);

  return (
    <div className='!bg-midnight-blue lg:!bg-jet-black relative'>
      <img src='/images/mobile-graph.svg' alt='mobile graph' className='absolute right-0 flex lg:hidden top-[95px] z-[1]' />
      <div className='w-full m-auto lg:pb-[220px] relative lg:sticky lg:top-[95px] !bg-jet-black'>
        <img src={portfolioItems[activeSlide].graph} alt='graph image' className='absolute right-0 hidden lg:flex' />
        <h2 className='font-bold text-[32px]/[51px] lg:text-[54px]/[51px] text-white text-center mb-[34px] pt-[56px]'>
          NettyWorth <span className='text-primary font-bold text-[32px]/[51px] lg:text-[54px]/[51px]'>Portfolio</span>
        </h2>
        <div className='hidden justify-between gap-10 !bg-transparent lg:flex relative max-w-[1366px] w-full m-auto px-10 xl:pl-[171px] xl:pr-[117px]'>
          <div className={cn('w-full relative !bg-transparent')}>
            {portfolioItems.map(({ phone }, idx) => {
              return <img key={phone} src={phone} className={cn('h-[700px] transition-all ease-linear duration-500 absolute top-0', activeSlide === idx ? 'opacity-100' : 'opacity-0')} alt='phone portfolio' />;
            })}
          </div>
          <div className='max-h-[685px] !bg-transparent w-full flex flex-col gap-[22px]'>
            {portfolioItems.map((item, index) => (
              <PortfolioItem item={item} key={index} activeSlide={activeSlide} handleChangeSlide={handleChangeSlide} index={index} stickySlide={true} />
            ))}
          </div>
        </div>
        <div className='flex lg:hidden !bg-transparent pl-5'>
          <Swiper loop={true} speed={500} slidesPerView={1.5} spaceBetween={22} className='[&_.swiper-slide]:!bg-transparent !bg-transparent'>
            {portfolioItems.map((item, index) => (
              <SwiperSlide key={index}>
                <PortfolioItem item={item} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default PortfolioSection;
