import MainPage from '@/components/dashboard/home/Home';

function App() {
  return <MainPage />;
}

App.isPublic = true;
App.metadata = {
  title: 'Discover Your NFT & Crypto Portfolio with NettyWorth | View Total Value, Receive Live Price Alerts, and Wallet Notifications.',
  description: ' NettyWorth presents real-time monitoring for your NFT and Cryptocurrency investments. Easily connect your wallets to oversee your total portfolio value and assets, track favorite NFT and Crypto projects, and much more.',
  image: `${process.env.NEXT_PUBLIC_SITE_URL}/thumbnail.png`,
  contentType: 'product',
};

export default App;
