import { StaticImageData } from 'next/image';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import { cn } from '@/utils/helper';

interface IHomeSwiper {
  reverse?: boolean;
  data: {
    name: string;
    text: string;
    img: StaticImageData;
  }[];
}
const HomeSwiper: React.FC<IHomeSwiper> = ({ reverse, data }) => {
  return (
    <Swiper
      spaceBetween={30}
      loop={true}
      autoplay={{
        delay: 0,
        disableOnInteraction: false,
        reverseDirection: reverse,
      }}
      modules={[Autoplay]}
      speed={6000}
      className={cn('!bg-transparent [&_.swiper-slide]:!bg-transparent', !reverse ? '[&_.swiper-wrapper]:pl-56' : '')}
      breakpoints={{
        1700: {
          slidesPerView: 4.2,
          spaceBetween: 30,
        },
        1200: {
          slidesPerView: 3.25,
        },
        1024: {
          slidesPerView: 2.8,
        },
        500: {
          slidesPerView: 2.1,
        },
        320: {
          slidesPerView: 1.2,
        },
      }}
    >
      {[...data, ...data].map((feedback, idx) => {
        return (
          <SwiperSlide key={idx}>
            <div className='py-7 rounded-lg !bg-char-coal [&_div]:!bg-char-coal max-w-[400px] min-[1366px]:max-w-max w-full h-[246px] lg:h-[300px] xl:h-[246px]'>
              <div className='pl-[22px] flex gap-[13px] items-center '>
                <img alt={feedback.name} src={feedback.img.src} className='size-[45px] rounded-full object-cover' />
                <p className='text-white font-normal text-[18px]'>{feedback.name}</p>
              </div>
              <div className='pl-8 pr-[26px] mt-[17px]'>
                <p className='text-white font-normal text-sm/5 lg:text-xl' dangerouslySetInnerHTML={{ __html: feedback.text }}></p>
              </div>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default HomeSwiper;
