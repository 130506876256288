import { homeFeedback } from 'constant';

import HomeSwiper from './HomeSwiper';

const Feedback = () => {
  return (
    <div id='feedback' className='!bg-midnight-blue lg:!bg-jet-black pb-20 lg:pb-[110px] -mt-[240px] lg:mt-0'>
      <h2 className='font-bold text-[32px]/[30px] lg:text-[54px]/[45px] text-white text-center relative mb-[14px] lg:mb-[93px] px-5 lg:px-0'>
        What our users are <span className='text-primary font-bold text-[32px]/[25px] lg:text-[54px]/[45px]'>saying</span>
      </h2>
      <HomeSwiper reverse={true} data={homeFeedback} />
      <div className='h-[30px] !bg-transparent'></div>
      <HomeSwiper data={homeFeedback} />
    </div>
  );
};

export default Feedback;
